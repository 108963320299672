tr.deactive{
    background-color: #ffd9dd;
}

.list_name{
  min-width: 90px;  
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}

.addDropdown {
    .dropdown-menu {
        left: initial !important;
        right: 0 !important;
        top: 110% !important;
        transform: none !important;
    }
}

.nowrap {
    white-space: nowrap;
}

.phoneInput {
    display: flex;
    justify-content: space-between;

    >*:first-child {
        width: 60px;
    }

    & *:last-child {
        width: calc(100% - 70px);
    }
}

.modal-dialog-md {
    max-width: 900px !important;
}

.tabs {
    display: flex;
    border-bottom: 2px solid #ccc;
}

.tab {
    padding: 10px 20px;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
}

.tab:hover {
    background-color: #f0f0f0;
}

.tab.active {
    border-bottom: 2px solid #007bff; /* Active tab color */
    color: #007bff; /* Active tab text color */
    font-weight: bold;
}

