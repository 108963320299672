.profileImage {
    width: 75px;
    height: 73px;
    border-radius: 50%;
    object-fit: contain;
}

.profileImageLabel {
    margin: 0;
    position: relative;

    >i {
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}

.pprofile1 {
    padding: 21px 22px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 12px;
}

p.bg-light.rounded.py-2.fieldcls {
    background-color: #775DA62b !important;

}

.ViewUser {
    // font-weight: 500;
    color: #202020;
    font-weight: 600;
    font-size: 23px;
    // margin-bottom: 32px;
}
.prophone .form-control {
width: 100% !important;
}
.pro_edit {
    background: #0069de;
    color: #fff;
    padding: 5px;
    font-size: 15px;
    border-radius: 40px;
    position: absolute;
    left: -63px;
    top:-12px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.pro_close{
    background: #0069de;
    color: #fff;
    padding: 5px;
    font-size: 15px;
    border-radius: 40px;
    position: absolute;
    left: -34px;
    top: -12px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}